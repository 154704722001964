














































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Home from './Home.vue'
import Sidebar from './Sidebar.vue'
import Create from './Create.vue'
import Edit from './Edit.vue'

@Component({
  components: {
    Home,
    Sidebar,
    Create,
    Edit
  }
})
export default class BlockEditor extends Vue {
  @Prop({ type: String, required: true }) environmentId!: String
  @Prop({ type: String }) componentId?: String

  drawer = false
  lastUpdated = Date.now()

  mounted() {
    //  Launch drawer if no component is selected
    if (this.$vuetify.breakpoint.mdAndUp || !this.componentId) {
      this.drawer = true
    }
  }

  handleUpdate() {
    this.lastUpdated = Date.now()
  }

  async handleError(e: any) {
    if (
      e.graphQLErrors &&
      e.graphQLErrors.some((ge: any) => ge.path.includes('preview'))
    )
      return
    await this.$store.dispatch('snackbar/showSnackbar', {
      text: 'Error: ' + e.message,
      color: 'error'
    })
  }
}
