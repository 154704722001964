
























































































































































import gql from 'graphql-tag'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class BlockEditorCreate extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string

  name = ''
  type = ''
  creating = false
  nameErrors: string[] = []

  async create() {
    this.creating = true
    try {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation createBlock(
            $environmentId: ID
            $name: String
            $type: String
          ) {
            createBlock(
              environmentId: $environmentId
              name: $name
              type: $type
            ) {
              _id
            }
          }
        `,
        variables: {
          environmentId: this.environmentId,
          name: this.name,
          type: this.type
        }
      })
      const { _id: blockId } = data.createBlock
      this.$emit('created')
      return this.$router.push({
        name: 'adminBlockEdit',
        params: {
          environmentId: this.environmentId,
          componentId: blockId
        }
      })
    } catch (e) {
      console.error(e)
      if (e.graphQLErrors) {
        e.graphQLErrors.forEach((err: any) => {
          if (err.error === 'validationError') {
            this.nameErrors = ['Debe ser único']
          }
        })
      }
      return this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: 'Ocurrió un error al intentar crear el bloque de contenido.'
      })
    } finally {
      this.creating = false
    }
  }
}
