















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import fieldTypes from '@/components/fields'

export interface ParamDefinition {
  type: 'string' | 'number' | 'boolean'
  name: string
  value: string | number | boolean
}

export interface ValueType {
  label: string
  icon: string
  field: any
  inline?: boolean
}

@Component({})
export default class PreviewParams extends Vue {
  @Prop({ type: Array, default: () => [] }) value!: ParamDefinition[]
  @Prop({ type: Boolean, default: false }) strict!: boolean

  params: ParamDefinition[] = []

  mounted() {
    this.updateParams()
  }

  @Watch('value')
  updateParams() {
    this.params = [...this.value]
  }

  setValueType(item: ParamDefinition, type: 'string' | 'number' | 'boolean') {
    if (this.strict) return
    item.type = type
    this.$emit('input', this.params)
  }

  setValue(item: ParamDefinition, value: string | number | boolean) {
    item.value = value
    this.$emit('input', this.params)
  }

  deleteParam(item: ParamDefinition) {
    if (this.strict) return
    this.params.splice(this.params.indexOf(item), 1)
    this.$emit('input', this.params)
  }

  addParam() {
    if (this.strict) return
    this.params.push({ name: '', type: 'string', value: '' })
    this.$emit('input', this.params)
  }

  valueTypes: Record<string, ValueType> = {
    string: {
      label: 'Texto',
      icon: 'text_format',
      field: fieldTypes.string.Field
    },
    number: {
      label: 'Número',
      icon: 'looks_4',
      field: fieldTypes.number.Field
    },
    boolean: {
      label: 'Verdadero/Falso',
      icon: 'check_circle',
      field: fieldTypes.checkbox.Field,
      inline: true
    }
  }
}
