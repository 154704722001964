var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"headers":[
        { text: 'Parámetro', value: 'name', sortable: false },
        !_vm.strict && { text: 'Tipo', value: 'type', sortable: false },
        { text: 'Valor', value: 'value', sortable: false },
        { text: '', value: '', sortable: false, align: 'right' }
      ].filter(function (c) { return c; }),"items":_vm.params,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function(r){return [_c('tr',[_c('td',[(!_vm.strict)?_c('v-edit-dialog',{attrs:{"return-value":r.item.name},on:{"update:returnValue":function($event){return _vm.$set(r.item, "name", $event)},"update:return-value":function($event){return _vm.$set(r.item, "name", $event)},"save":function($event){return _vm.$emit('input', _vm.params)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Parámetro","single-line":""},model:{value:(r.item.name),callback:function ($$v) {_vm.$set(r.item, "name", $$v)},expression:"r.item.name"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(r.item.name)+" ")]):_c('span',{domProps:{"textContent":_vm._s(r.item.name)}})],1),(!_vm.strict)?_c('td',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"cursor":"pointer"}},on),[_c('v-icon',{attrs:{"size":"16"},domProps:{"textContent":_vm._s(_vm.valueTypes[r.item.type].icon)}}),_vm._v(" "+_vm._s(_vm.valueTypes[r.item.type].label)+" "),_c('v-icon',{attrs:{"size":"16"}},[_vm._v("arrow_drop_down")])],1)]}}],null,true)},[_c('v-list',{staticClass:"scroll-y",staticStyle:{"max-height":"50vh"}},_vm._l((_vm.valueTypes),function(vt,typeName){return _c('v-list-item',{key:typeName,on:{"click":function($event){return _vm.setValueType(r.item, typeName)}}},[_c('v-list-item-avatar',[_c('v-icon',{domProps:{"textContent":_vm._s(vt.icon)}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(vt.label)}})],1)}),1)],1)],1):_vm._e(),_c('td',[(_vm.valueTypes[r.item.type].inline)?[_c(_vm.valueTypes[r.item.type].field,{tag:"component",attrs:{"name":"value","value":r.item.value,"schema":{
                label: ' ',
                optional: true
              }},on:{"input":function($event){return _vm.setValue(r.item, _vm.value)}}})]:[_c('v-edit-dialog',{attrs:{"return-value":r.item.value},on:{"update:returnValue":function($event){return _vm.$set(r.item, "value", $event)},"update:return-value":function($event){return _vm.$set(r.item, "value", $event)},"save":function($event){return _vm.$emit('input', _vm.params)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c(_vm.valueTypes[r.item.type].field,{tag:"component",attrs:{"name":"value","schema":{
                    label: ' ',
                    optional: true
                  }},model:{value:(r.item.value),callback:function ($$v) {_vm.$set(r.item, "value", $$v)},expression:"r.item.value"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(r.item.value)+" ")])]],2),_c('td',{attrs:{"width":"48"}},[_c('v-btn',{attrs:{"text":"","icon":"","color":"error","disabled":_vm.strict},on:{"click":function($event){return _vm.deleteParam(r.item)}}},[_c('v-icon',[_vm._v("delete")])],1)],1)])]}}])}),_c('div',{staticClass:"text-center mt-2"},[(!_vm.strict)?_c('v-btn',{attrs:{"block":"","depressed":"","color":"green","dark":""},on:{"click":_vm.addParam}},[_vm._v("Añadir Nuevo Parámetro")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }